import PricingLanding from "../components/pricing/landing/Landing";

const Pricing = () => {
  return (
    <>
      <PricingLanding />
    </>
  );
};

export default Pricing;

import styles from "./Simple.module.scss";

import pointer from "../../../assets/imgs/finger-pointer.png";
import image from "../../../assets/imgs/simple.png";

const Simple = () => {
  return (
    <div className={styles.simple}>
      <div className={`content-wrpr`}>
        <div className={`${styles.listWrpr}`}>
          <div className={`tac ${styles.sec}`}>
            <div className={`df aic ffm fw600 fs18 ${styles.pill}`}>
              Invite <img src={pointer} alt="" />
            </div>
            <h5 className={`ffm fw700 fs20`}>Family</h5>
            <p className={`ffm fw500 fs16`}>
              Broken down by each parent and grandparent’s side
            </p>
          </div>
          <div className={`tac ${styles.sec}`}>
            <div className={`df aic ffm fw600 fs18 ${styles.pill}`}>
              Invite <img src={pointer} alt="" />
            </div>
            <h5 className={`ffm fw700 fs20`}>Mechutanim</h5>
            <p className={`ffm fw500 fs16`}>
              As expansive as you wish to be (e.g. their children, siblings,
              mechutanim etc.)
            </p>
          </div>
          <div className={`tac ${styles.sec}`}>
            <div className={`df aic ffm fw600 fs18 ${styles.pill}`}>
              Invite <img src={pointer} alt="" />
            </div>{" "}
            <h5 className={`ffm fw700 fs20`}>Grade mates</h5>
            <p className={`ffm fw500 fs16`}>
              We match your Yeshivah grade list to invite all your grade mates
              easily
            </p>
          </div>
          <div className={`tac ${styles.sec}`}>
            <div className={`df aic ffm fw600 fs18 ${styles.pill}`}>
              Invite <img src={pointer} alt="" />
            </div>{" "}
            <h5 className={`ffm fw700 fs20`}>Friends</h5>
            <p className={`ffm fw500 fs16`}>
              With information gathered through our sophisticated algorithms.
            </p>
          </div>
          <div className={`tac ${styles.sec}`}>
            <div className={`df aic ffm fw600 fs18 ${styles.pill}`}>
              Invite <img src={pointer} alt="" />
            </div>{" "}
            <h5 className={`ffm fw700 fs20`}>Neighbors</h5>
            <p className={`ffm fw500 fs16`}>
              Searchable by street, neighborhood, address numbers, or a simple
              circle around your home, your choice{" "}
            </p>
          </div>
        </div>
        <h3 className={`ffm fw800 fs28 tac`}>
          IT'S SIMPLE. IT'S CONVENIENT. AND IT'S FOR YOU.
        </h3>
        <p className={`ffm fw500 fs20 tac ${styles.text}`}>
          Every list is customizable to meet your needs. We offer an array of
          personalized features so that you can plan for a beautiful simcha with
          friends and family
        </p>

        <div className={`df aic`}>
          <a
            href="https://portal.genvite.com/auth/signup"
            rel="noopener noreferrer"
            className={`ffm fw600 fs18`}
          >
            Try Genvite Free
          </a>
        </div>

        <img className={`${styles.bgImg}`} src={image} alt="" />
      </div>
    </div>
  );
};

export default Simple;

import styles from "./Landing.module.scss";

const Landing = () => {
  return (
    <div className={`bc145F95 cfff ${styles.storyLanding}`}>
      <div className={`content-wrpr`}>
        <h2 className={`ffm fs32 fw800 tac`}>OUR STORY</h2>
        <p className={`ffm fs24 fw500 tac`}>
          E-Z Labels was established in 2002 to simplify the work of those
          sending out larger mail shipments. From Mechutanim to organizations,
          the firm has earned a reputation as the premier source for reliable
          contact lists and seamless labeling and mailing services.
        </p>
        <p className={`ffm fs24 fw500 tac`}>
          But there was more work to be done. While automation was taking over
          the world, those marrying off children or making a simcha were still
          left with the tedious task of collecting names and updated addresses
          from ever-growing family networks.
        </p>
        <p className={`ffm fs24 fw500 tac`}>
          Enter Genvite, the solution that’s changing the game for Frum families
          during their special time. With tens of thousands of entries and a
          virtually limitless number of sorting options, sending invitations is
          now just a click away.
        </p>
      </div>
    </div>
  );
};

export default Landing;

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import styles from "./Feedback.module.scss";
import axios from "axios";
import { useState } from "react";

const emailRegex = /([a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/;

const Feedback = () => {
  const [email, setEmail] = useState("");

  const sendEmail = async () => {
    let re = await axios
      .post(
        "https://emailsenderprod.azurewebsites.net/api/emailSenderProduction",
        {
          fromEmail: "admin@shmili.com",
          toEmail: "subscribe@genvite.com",
          subject: "Email subscription from genvite.com",
          data: [{ label: "Email", value: email }],
        }
      )
      .catch((e) => e);

    if (re.data === "OK") {
      setEmail("");
    }

    // https://emailsenderprod.azurewebsites.net/api/emailSenderProduction
  };

  const notEmail = () => {
    console.log(!emailRegex.test(email));
    return !email.length || !emailRegex.test(email);
  };

  return (
    <div className={styles.feedback}>
      <div className={`content-wrpr`}>
        <div className={styles.swiperWrpr}>
          <div className={styles.desktop}>
            <Swiper
              spaceBetween={0}
              slidesPerView={3}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              //   autoplay={{ delay: 5000, disableOnInteraction: false }}
              modules={[Autoplay, Pagination, Navigation]}
              pagination={{
                clickable: true,
              }}
              //   loop={true}
            >
              <SwiperSlide>
                <div className={`df ffc ${styles.slide}`}>
                  <p className={`ffm fw450 fs20 ${styles.content}`}>
                    I must admit that I was shocked to see such technology in
                    the heimish market. There’s no going back after using this!
                  </p>
                  <div className={styles.sigWrpr}>
                    <p className={`ffm fw500 fs22 ${styles.sig}`}>Shloimy K.</p>
                    <p className={`ffm fw500 fs22 ${styles.sig}`}>
                      Williamsburg, NY
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={`df ffc ${styles.slide}`}>
                  <p className={`ffm fw450 fs20 ${styles.content}`}>
                    Great service! This was our first simcha where we didn’t
                    miss anyone we wanted to invite. You’re doing it wrong if
                    you’re not using Genvite.
                  </p>
                  <div className={styles.sigWrpr}>
                    <p className={`ffm fw500 fs22 ${styles.sig}`}>Family F.</p>
                    <p className={`ffm fw500 fs22 ${styles.sig}`}>
                      Kiryas Yoel
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={`df ffc ${styles.slide}`}>
                  <p className={`ffm fw450 fs20 ${styles.content}`}>
                    Genvite changed our invitation experience! What used to be
                    stressful and difficult has turned into a simple and
                    enjoyable process.
                  </p>
                  <div className={styles.sigWrpr}>
                    <p className={`ffm fw500 fs22 ${styles.sig}`}>
                      David Aron M.
                    </p>
                    <p className={`ffm fw500 fs22 ${styles.sig}`}>Monsey, NY</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={`df ffc ${styles.slide}`}>
                  <p className={`ffm fw450 fs20 ${styles.content}`}>
                    Kudos to Genvite! Every challenge in the process of building
                    my invitation list, from gathering and grouping my
                    acquaintances, to having their updated addresses and phone
                    numbers is covered in this platform. Thank you!
                  </p>
                  <div className={styles.sigWrpr}>
                    <p className={`ffm fw500 fs22 ${styles.sig}`}>Chaim R.</p>
                    <p className={`ffm fw500 fs22 ${styles.sig}`}>
                      Brooklyn, NY
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className={styles.mobile}>
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              //   autoplay={{ delay: 5000, disableOnInteraction: false }}
              modules={[Autoplay, Pagination, Navigation]}
              pagination={{
                clickable: true,
              }}
              //   loop={true}
            >
              <SwiperSlide>
                <div className={`df ffc ${styles.slide}`}>
                  <p className={`ffm fw450 fs20 ${styles.content}`}>
                    I must admit that I was shocked to see such technology in
                    the heimish market. There’s no going back after using this!
                  </p>
                  <div className={styles.sigWrpr}>
                    <p className={`ffm fw500 fs22 ${styles.sig}`}>Shloimy K.</p>
                    <p className={`ffm fw500 fs22 ${styles.sig}`}>
                      Williamsburg, NY
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={`df ffc ${styles.slide}`}>
                  <p className={`ffm fw450 fs20 ${styles.content}`}>
                    Great service! This was our first simcha where we didn’t
                    miss anyone we wanted to invite. You’re doing it wrong if
                    you’re not using Genvite.
                  </p>
                  <div className={styles.sigWrpr}>
                    <p className={`ffm fw500 fs22 ${styles.sig}`}>Family F.</p>
                    <p className={`ffm fw500 fs22 ${styles.sig}`}>
                      Kiryas Yoel
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={`df ffc ${styles.slide}`}>
                  <p className={`ffm fw450 fs20 ${styles.content}`}>
                    Genvite changed our invitation experience! What used to be
                    stressful and difficult has turned into a simple and
                    enjoyable process.
                  </p>
                  <div className={styles.sigWrpr}>
                    <p className={`ffm fw500 fs22 ${styles.sig}`}>
                      David Aron M.
                    </p>
                    <p className={`ffm fw500 fs22 ${styles.sig}`}>Monsey, NY</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={`df ffc ${styles.slide}`}>
                  <p className={`ffm fw450 fs20 ${styles.content}`}>
                    Kudos to Genvite! Every challenge in the process of building
                    my invitation list, from gathering and grouping my
                    acquaintances, to having their updated addresses and phone
                    numbers is covered in this platform. Thank you!
                  </p>
                  <div className={styles.sigWrpr}>
                    <p className={`ffm fw500 fs22 ${styles.sig}`}>Chaim R.</p>
                    <p className={`ffm fw500 fs22 ${styles.sig}`}>
                      Brooklyn, NY
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div className={`df aic ${styles.newsLetter}`}>
          <div>
            <h5 className={`ffi fw600 fs36`}>Sign up for our newsletter</h5>
            <p className={`ffi fw400 fs20 ${styles.sub}`}>
              Be the first to know about new updates news and insights.
            </p>
          </div>

          <div className={`mla`}>
            <div className={`df aic ${styles.inputSec}`}>
              <input
                className={`ffi fs16 fw400`}
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                disabled={notEmail()}
                onClick={sendEmail}
                className={`ffi fs16 fw600`}
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={`ffm fw500 fs22 tac ${styles.letUs}`}>
        LET US HELP GET THE WORD OUT
      </div>
    </div>
  );
};

export default Feedback;

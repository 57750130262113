import styles from "./Easy.module.scss";

import autoBuild from "../../../assets/imgs/auto-build.png";
import effectiveManage from "../../../assets/imgs/effective-manage.png";
import multiOptions from "../../../assets/imgs/multi-options.png";

import filter from "../../../assets/imgs/street-filter.png";
import Discover from "../../discover/Discover";

const Easy = () => {
  return (
    <>
      <div className={styles.easy}>
        <div className={`content-wrpr`}>
          <h5 className={`tac ffm fw700 fs22`}>
            ENJOY OUR POWERFUL SEARCH TOOLS AT NO COST!
          </h5>

          <div className={`df aic ${styles.list}`}>
            <div className={`df aic ${styles.sec}`}>
              <div className={`df acc ${styles.iconWrpr}`}>
                <img src={autoBuild} alt="" />
              </div>
              <p className={`ffm fw600 fs18`}>Automatic Contact builder</p>
            </div>
            <div className={`df aic ${styles.sec}`}>
              <div className={`df acc ${styles.iconWrpr}`}>
                <img src={effectiveManage} alt="" />
              </div>
              <p className={`ffm fw600 fs18`}>Effortless List Management</p>
            </div>
            <div className={`df aic ${styles.sec}`}>
              <div className={`df acc ${styles.iconWrpr}`}>
                <img src={multiOptions} alt="" />
              </div>
              <p className={`ffm fw600 fs18`}>Multi Invite Type Options</p>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.filterBy}`}>
        <div className={`content-wrpr`}>
          <h5 className={`tac ffm fw700 fs30 c084571 ttuc`}>
            Advanced map filtering
          </h5>

          <img src={filter} alt="" />

          <div className={`df aic`}>
            <a
              href="https://portal.genvite.com/auth/signup"
              rel="noopener noreferrer"
              className={`ffm fw600 fs18 bc084571 cfff`}
            >
              Try Genvite Free{" "}
            </a>
          </div>
        </div>
      </div>

      <Discover />

      <div className={`ffm fs22 fw500 ttuc tac bc145F95 cfff ${styles.bar}`}>
        LET US HELP GET THE WORD OUT
      </div>
    </>
  );
};

export default Easy;

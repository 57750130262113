import { useState } from "react";
import axios from "axios";

import styles from "./Contact.module.scss";

import icon from "../assets/imgs/email-icon.png";

const emailRegex = /([a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/;

const Contact = () => {
  const [disabled, setDisabled] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = async () => {
    setDisabled(true);
    let re = await axios
      .post(
        "https://emailsenderprod.azurewebsites.net/api/emailSenderProduction",
        {
          fromEmail: "admin@shmili.com",
          toEmail: "website@genvite.com",
          subject: "Email contact from genvite.com",
          data: [
            { label: "Name", value: `${firstName} ${lastName}` },
            { label: "Email", value: email },
            { label: "Phone", value: phone },
            { label: "Message", value: message },
          ],
        }
      )
      .catch((e) => e);

    if (re.data === "OK") {
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhone("");
      setMessage("");
    }

    setDisabled(false);
    // https://emailsenderprod.azurewebsites.net/api/emailSenderProduction
  };

  //   Phone (646)927-1198

  // Fax (718)506-1075

  // Email info@genvite.com

  // Location
  // 8 Stropkov Court Suite 103, Monroe, NY 10950
  return (
    <div>
      <div className={`content-wrpr ${styles.contentWrpr}`}>
        <div className={`df ${styles.innerWrpr}`}>
          <div className={`df acc ${styles.iconWrpr}`}>
            <img src={icon} alt="" />
          </div>
          <div className={styles.sec}>
            <h3 className={`ffm fw700 fs35 c18375E`}>Contact Us</h3>
            <h5 className={`ffm fw7500 fs18 c18375E`}>
              We value your comments, suggestions, complaints, compliments and
              feedback.
            </h5>

            <p className={`ffm fs15 fw700 c1E6196 ${styles.label}`}>Phone</p>
            <p className={`ffm fw300 fs12 c1E6196 ${styles.val}`}>
              646.927.1198
            </p>

            <p className={`ffm fs15 fw700 c1E6196 ${styles.label}`}>Fax</p>
            <p className={`ffm fw300 fs12 c1E6196 ${styles.val}`}>
              718.506.1075
            </p>

            <p className={`ffm fs15 fw700 c1E6196 ${styles.label}`}>Email</p>
            <p className={`ffm fw300 fs12 c1E6196 ${styles.val}`}>
              info@genvite.com{" "}
            </p>

            <p className={`ffm fs15 fw700 c1E6196 ${styles.label}`}>Location</p>
            <p className={`ffm fw300 fs12 c1E6196 ${styles.val}`}>
              8 Stropkov Court Suite 103, Monroe, NY 10950
            </p>
          </div>
          <div className={styles.sec}>
            <div className={`df aic ${styles.line} ${styles.multi}`}>
              <input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className={`ffm fs14 c4377B3`}
                placeholder="First Name"
              />
              <input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className={`ffm fs14 c4377B3`}
                placeholder="Last Name"
              />
            </div>
            <div className={`df aic ${styles.line} `}>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={`ffm fs14 c4377B3`}
                placeholder="Email Address"
              />
            </div>
            <div className={`df aic ${styles.line} `}>
              <input
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className={`ffm fs14 c4377B3`}
                placeholder="Phone Number"
              />
            </div>

            <div className={`df aic ${styles.line} `}>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className={`ffm fs14 c4377B3`}
                placeholder="Message"
              />
            </div>
            <button
              onClick={sendEmail}
              disabled={
                !firstName ||
                !lastName ||
                !email ||
                !emailRegex.test(email) ||
                !phone ||
                !message ||
                disabled
              }
              className={`ffm fw450 fs14 bc1E6196 cfff`}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

import styles from "./YourCall.module.scss";

const YourCall = () => {
  return (
    <div className={styles.yourCall}>
      <div className={`content-wrpr`}>
        <h3 className={`tac ffm fw800 fs32`}>Your Simcha. Your Call.</h3>
        <p className={`ffm fs20 fw500 tac`}>
          Every list is customizable to meet your needs. We offer an array of
          personalized features so that you can plan for a beautiful simcha with
          friends and family
        </p>

        <div className={`df aic`}>
          <a
            href="https://portal.genvite.com/auth/signup"
            rel="noopener noreferrer"
            className={`ffm fw600 fs18`}
          >
            Try Genvite Now{" "}
          </a>
        </div>
      </div>
    </div>
  );
};

export default YourCall;

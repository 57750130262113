import { Link } from "react-router-dom";

import styles from "./Landing.module.scss";

const Landing = () => {
  return (
    <div className={styles.landing}>
      <div className={`df aic content-wrpr ${styles.contentWrpr}`}>
        <div className={`${styles.content}`}>
          <h2 className={`ffbn fs96`}>CLICK, CLICK, INVITE.</h2>
          <p className={`ffm fw600 fs20`}>
            Genvite is the revolutionary platform where your contact and
            invitation lists are made easy as a breeze
          </p>
          <div className={`df aic ${styles.buttonsWrpr}`}>
            <a
              rel="noopener noreferrer"
              className={`ffm fw600 fs14 ${styles.button} ${styles.started}`}
              href="https://portal.genvite.com/auth/signup"
            >
              Get Started
            </a>
            <Link
              className={`ffm fw600 fs14 ${styles.button}`}
              to="/how-it-works"
            >
              How It Works
            </Link>
          </div>
        </div>
        <div className={`df acc mla ${styles.imgSec}`}>
          <div className={`${styles.imgWrpr}`}></div>
        </div>
      </div>
    </div>
  );
};

export default Landing;

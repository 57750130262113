import "./App.module.scss";
import Header from "./components/header/Header";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Platform from "./pages/Platform";
import Footer from "./components/footer/Footer";
import Pricing from "./pages/Pricing";
import Contact from "./pages/Contact";
import HowItWorks from "./pages/HowItWorks";
import OurStory from "./pages/OurStory";
import Services from "./pages/Services";
import PrivacyPopup from "./components/term-popup/PrivacyPopup";
import TermsPopup from "./components/term-popup/TermsPopup";
import { useEffect, useLayoutEffect, useState } from "react";

const Wrapper = ({ children }) => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    document.getElementsByClassName("App")[0].scrollTo(0, 0);
  }, [pathname]);
  return children;
};

function App() {
  const [show, setShow] = useState(false)
  const location = useLocation();

  useEffect(() => {
    const isInitialLoad = window.performance
      .getEntriesByType("navigation")[0];

      if (location.pathname === "/" && (isInitialLoad.type === "reload" || isInitialLoad.type === "navigate")) {
        setTimeout(() => {
          setShow(true);
        }, 700);
      }
  // eslint-disable-next-line
  }, []);

  return (
    <div className="App">
      {/* <BrowserRouter> */}
        <Wrapper>
          <Header />
          <Routes>
            <Route path="" element={<Home show={show} setShow={setShow} />}></Route>
            <Route path="/platform" element={<Platform />}></Route>
            <Route path="/pricing" element={<Pricing />}></Route>
            <Route path="/services" element={<Services />}></Route>
            <Route path="/our-story" element={<OurStory />}></Route>
            <Route path="/how-it-works" element={<HowItWorks />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/privacy" element={<PrivacyPopup />}></Route>
            <Route path="/terms" element={<TermsPopup />}></Route>
          </Routes>
          <Footer />
        </Wrapper>
      {/* </BrowserRouter> */}
    </div>
  );
}

export default App;

import styles from "./TheAdvantage.module.scss";

import theAdvantage from "../../assets/imgs/the-advantage.png";
import advantageCircle from "../../assets/imgs/advantage-circle.png";
import listIcon from "../../assets/imgs/list-icon.png";

const TheAdvantage = () => {
  return (
    <div className={styles.theAdvantage}>
      <div className={`content-wrpr`}>
        <h3 className={`tac ffm fw700 fs36`}>The Genvite Advantage</h3>
        <div className={`df aic ${styles.innerContentWrpr}`}>
          <div className={`${styles.imgSec}`}>
            <img
              className={`${styles.circleImg}`}
              src={advantageCircle}
              alt=""
            />
            <img className={`${styles.mainImg}`} src={theAdvantage} alt="" />
          </div>

          <div className={`${styles.textSec}`}>
            <div className={`df aic ${styles.line}`}>
              <img src={listIcon} alt="" />
              <p className={`ffi fs18 fw600`}>
                Remove the stress of compiling wedding invitation lists and
                confirming information accuracy
              </p>
            </div>
            <div className={`df aic ${styles.line}`}>
              <img src={listIcon} alt="" />
              <p className={`ffi fs18 fw600`}>
                Update your lists seamlessly and automatically as addresses
                change and families grow
              </p>
            </div>
            <div className={`df aic ${styles.line}`}>
              <img src={listIcon} alt="" />
              <p className={`ffi fs18 fw600`}>
                Work off suggested contact lists including family, mechutanim,
                grade mates, neighbors etc.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TheAdvantage;

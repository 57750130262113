import landingImg from "../../assets/imgs/service-landing.png";

import styles from "./Landing.module.scss";

const Landing = () => {
  return (
    <div className={styles.serviceLanding}>
      <div className={`df aic content-wrpr ${styles.contentWrpr}`}>
        <img src={landingImg} alt="" />
        <div className={styles.content}>
          <h3 className={`ffm fs32 fw800 c084571`}>
            Many ways to invite with Genvite
          </h3>
          <p className={`ffm fs20 fw500 c084571`}>
            As a full service agency, we accommodate self-service mailing,
            providing complete mailing service if requested, as well as offer
            phone calls, text messages and other methods of communication.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Landing;

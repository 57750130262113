import styles from "./AutoSuggestions.module.scss";

const AutoSuggestions = () => {
  return (
    <div className={styles.autoSuggestions}>
      <div className={`content-wrpr`}>
        <h3 className={`ffm fw700 fs28 tac`}>
          Automatic Directory Suggestions
        </h3>
        <p className={`ffm fw600 fs18 tac`}>
          Our software provides automatic lists with the names of addresses of
          the people you wish to invite, sparing you the need to painstakingly
          gather the information. The list is divided into groups:
        </p>
      </div>
    </div>
  );
};

export default AutoSuggestions;

import styles from "./Genvite.module.scss";

import placeholder from "../../../assets/imgs/video-placeholder.png";
import OnlinePortal from "./online-portal/OnlinePortal";

const Genvite = () => {
  return (
    <div className={styles.genvite}>
      <div className={`df aic ${styles.landing}`}>
        <div className={styles.sec}>
          <h3 className={`ffm fs30 fw700 ttuc c084571`}>Discover genvite:</h3>

          <p className={`ffm fs20 fw500 c084571`}>
            Genvite enables clients to seamlessly build their personal
            invitation list, gathered from an updated and accurate community
            database.
          </p>
          <p className={`ffm fs20 fw500 c7E8DA8`}>
            (currently limited to the Satmar community directory of
            approximately 38,000).
          </p>
          <p className={`ffm fs20 fw500 c084571`}>
            Through sophisticated algorithms, each person in our database has
            his family connections listed, suggested contacts offered, and his
            personal information kept up-to-date.
          </p>
        </div>
        <div className={`df ${styles.sec} ${styles.imgSec}`}>
          <img src={placeholder} alt="" />
          <p className={`ffm fs32 ${styles.videoTitle}`}>
            Tutorial video coming soon...
          </p>
        </div>
      </div>
      <OnlinePortal />
    </div>
  );
};

export default Genvite;

import React from 'react'
import styles from "./service.module.scss"
import Rocket from "../../../../assets/imgs/rocket.svg"
import Download from "../../../../assets/imgs/download.svg"
import Label from "../../../../assets/imgs/label.svg"
import Email from "../../../../assets/imgs/email.svg"
import Call from "../../../../assets/imgs/call.svg"
import Message from "../../../../assets/imgs/message.svg"
import At from "../../../../assets/imgs/at.svg"

const Service = () => {

    const cardData = [
        {
            label: "Self-print Labels",
            icon: Download,
            text: "We offer ready-to-print templates, with a wide choice of fonts:",
            discount: [
                {heading: "Discounted price;", text: "$0.33 per contact"}
            ]
        },
        {
            label: "Genvite Labels",
            icon: Label,
            text: "Printed in-house by our team, with choice of fonts available:",
            discount: [
                {heading: "Discounted price;", text: "Large clear labels (14 per sheet) = $0.46"},
                {heading: "Discounted price;", text: "Regular white labels (30 per sheet) = $0.39"}
            ]
        },
        {
            label: "Print on Envelope",
            icon: Email,
            text: "We will print recipient’s address on the envelope, matching the font and style of the return address",
            discount: [
                {heading: "Discounted price;", text: "$0.55 per envelope (complete stuffing & mailing, +additional $0.30 + postage"},
            ]
        },
        {
            label: "Voice Calls",
            icon: Call,
            text: "Invite family and loved ones with a phone call",
            discount: [
                {heading: "Discounted price;", text: "$0.13 per call* (*Call duration up to 2 minutes)"},
            ]
        },
        {
            label: "Text Messages",
            icon: Message,
            text: "Send a text to their phone with your simcha invitation",
            discount: [
                {heading: "Discounted price;", text: "$0.13 per message"},
            ]
        },
        {
            label: "Mass Emails",
            icon: At,
            text: "Coming soon",
        }
    ]

  return (
    <>
    <div className={`mt-5 ${styles.services}`}>
        <div className={`content-wrpr px-0 px-md-2 py-5`}>
            <h3 className={`ffm fw700 fs32 tac ${styles.pricing}`} >PRICING FOR GENVITE SERVICES</h3>
            <p className={`ffm fw400 fs20 tac ${styles.firstP}`} >Only pay for what you need, No Minimum required.</p>

            <div className={`my-3 p-3 my-5 rounded-3 ${styles.banner}`}>
                <div className='row'>
                    <div className={`col-md-8 ${styles.rightBorder}`}>
                        <div className='row'>
                            <div className='col-3'>
                                <img className='object-fit-contain w-100 h-50' src={Rocket} alt="rocket" />
                            </div>
                            <div className='col-9'>
                                <p className='ffm fw500 fs14 cfff' >Platform Launch Special </p>
                                <h4 className={`ffm fs16 fw600 cfff mb-4 ${styles.limited}`}>Limited time offer </h4>
                                <h4 className='ffm fw700 fs24 cfff m-0' >Get 15% DISCOUNT For all services </h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 d-flex flex-column align-items-center'>
                        <p className='ffm fw500 fs14 tac cfff mt-4 mb-2' >Enter Promo code at checkout</p>
                        <h4 className={`ffm fw900 fs24 tac ${styles.welcome}`}>WELCOME15</h4>
                        <a href='https://portal.genvite.com/auth/signup' target='_self' className={`ffi ${styles.btn}`} >Sign Up today </a>
                    </div>
                </div>
            </div>

            <div className='row gy-3 mt-3'>
                {cardData.map((item, i)=>(
                    <div className='col-md-4' key={i}>
                        <Card data={item} />
                    </div>
                ))}
            </div>
        </div>
    </div>
    <div className={`py-5 ${styles.kick}`}>
        <div className='content-wrpr'>
            <p className={`ffi fw600 fs16 tac m-0 ${styles.kickP}`}>NEED SOMETHING YOU DON’T SEE?  REACH OUT AND OUR TEAM WILL BE HAPPY TO ASSIST YOU!</p>
            <h3 className={`ffi fw600 fs24 tac ${styles.kickH3}`} >KICK OFF YOUR SIMCHA WITH PEACE OF MIND </h3>
        </div>
    </div>
    </>
  )
}

export default Service


const Card = ({data}) =>{
    return (
        <div className={`card p-3 ${styles.cards} h-100 px-4`}>
            <div className={`card-header px-0 d-flex align-item-center justify-content-start gap-2 ${styles.cardHeader}`}>
                <img src={data.icon} alt="download icon" />
                <p className={`ffm fw700 fs18 m-0 ${styles.cardHeading}`} >{data.label}</p>
            </div>
            <div className={`card-body p-0 py-3 d-flex flex-column ${data.text === "Coming soon" ? "justify-content-center align-items-center" : "justify-content-between" } `}>
                <p className={`ffm fs16 ${styles.cardFirstP} ${data.text === "Coming soon" ? "fw700" : "fw500" } `}>{data.text}</p>
                
                <div>
                    {data?.discount?.map((item, i)=>(
                        <div className='' key={i}>
                            <p className={`ffm fs18 fw600 m-0 ${styles.cardSecondP}`}>{item.heading}</p>
                            <p className={`ffm fs14 fw500 m-0 ${styles.cardSecondP}`} >{item.text}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
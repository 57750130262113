import styles from "./Landing.module.scss";

const PlatformLanding = () => {
  return (
    <div className={styles.pLanding}>
      <div className={`df aic content-wrpr ${styles.contentWrpr}`}>
        <div className={`${styles.content}`}>
          <h2 className={`ffm fs34 fw700 ttuc`}>Genvite At A Glance:</h2>
          <p className={`ffm fs18 fw500 ${styles.first}`}>
            Offering a growing database of
          </p>
          <p className={`ffm fs28 fw700 ${styles.second}`}>
            over 38,000 Contacts
          </p>
          <p className={`ffm fs28 fw500 ${styles.third}`}>
            available at your fingertips.
          </p>
          <a
            href="https://portal.genvite.com/auth/signup"
            rel="noopener noreferrer"
            className={`ffm fw700 fs18`}
          >
            TRY GENVITE TODAY
          </a>
        </div>
        <div className={`df mla acc ${styles.imgSec}`}>
          <div className={styles.imageWrpr}></div>
        </div>
      </div>
    </div>
  );
};

export default PlatformLanding;

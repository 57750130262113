import styles from "./WeOffer.module.scss";

const WeOffer = () => {
  return (
    <div className={styles.weOffer}>
      <div className={`content-wrpr`}>
        <div className={styles.content}>
          <p className={`ffm fs28 fw600 c084571 ${styles.title}`}>
            Services We offer:
          </p>
          <p className={`ffm fs20 fw500 c084571 ${styles.subTitle}`}>
            We are currently offering the following services to our customers.
          </p>
          <p className={`ffm fs20 fw700 c084571 ${styles.hdrP}`}>
            1. Self-service mailing.
          </p>
          <p className={`ffm fs18 fw500 c084571 ${styles.descP}`}>
            Users will be able to create a printable file with labels made from
            a template we provide and printed by the user himself. The list can
            be created based on his entire My Contact list, specific group(s),
            or individual contacts selected.
          </p>
          <p className={`ffm fs20 fw700 c084571 ${styles.hdrP}`}>
            2. Company-provided mailing.
          </p>
          <p className={`ffm fs18 fw500 c084571 ${styles.descP}`}>
            The user will submit their job to be professionally printed by us.
            We offer various types of labels and sizes, as well as directly
            printing addresses on envelopes. We also offer a complete
            start-to-finish job from stuffing to stamping and mailing.
          </p>
          <p className={`ffm fs20 fw700 c084571 ${styles.hdrP}`}>
            3. Phone calls.
          </p>
          <p className={`ffm fs18 fw500 c084571 ${styles.descP}`}>
            The user will be able to send out a voice call to his selected
            contact list. He can schedule a date and time for the call and set
            the message by either recording on the computer, uploading a
            pre-recorded message, or type his text which we convert to speech.
          </p>
          <p className={`ffm fs20 fw700 c084571 ${styles.hdrP}`}>
            4. Text Messages.
          </p>
          <p className={`ffm fs18 fw500 c084571 ${styles.descP}`}>
            These can be scheduled for a specific date and time.
          </p>
          <p className={`ffm fs20 fw700 c084571 ${styles.hdrP}`}>
            5. PDF list.
          </p>
          <p className={`ffm fs18 fw500 c084571 ${styles.descP}`}>
            Get the selected contacts with all details in the format of a phone
            book.
          </p>
          <p className={`ffm fs20 fw700 c084571 ${styles.hdrP}`}>
            6. Mass Emails.
          </p>
          <p className={`ffm fs18 fw500 c084571 ${styles.descP}`}>
            Coming soon…
          </p>
        </div>
      </div>
      <div className={`df aic`}>
        <a
          href="https://portal.genvite.com/auth/signup"
          rel="noopener noreferrer"
          className={`ffm fw600 fs18 bc548ED1`}
        >
          Try Genvite Now
        </a>
      </div>
      <div className={styles.bg}></div>
    </div>
  );
};

export default WeOffer;

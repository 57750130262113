import React from 'react'
import PhoneBookImg from "../../../../assets/imgs/phonebook.png"
import styles from "./directory.module.scss"

const Directory = () => {
  return (
    <div className={`content-wrpr my-5`}>
        <div className='row rounded-3 py'>
            <div className={`col-md-8 p-4 ${styles.left}`}>
                <div className='row'>
                    <div className='col-md-8'>
                        <h3 className={`fw700 ffi ${styles.save}`} >Save $42</h3>
                        <h4 className={`m-0 fs22 fw700 ffi ${styles.monthly}`}>$3.50 <span className='ffi fw400 fs12' >Monthly</span> </h4>
                        <h4 className={`m-0 fs18 fw700 ffi my-2 cfff`} >With Satmar directory book  Promo code </h4>
                        <p className={`m-0 ffi fw500 fs16 cfff mt-4`}>Enter the promo code printed <br /> on your book at checkout </p>
                    </div>
                    <div className='col-md-4'>
                        <img className='object-fit-contain w-100' src={PhoneBookImg} alt="phonebook-img" />
                    </div>
                </div>
            </div>
            <div className={`col-md-4 py-3 px-5 ${styles.right} d-flex flex-column align-items-center`}>
                <h4 className={`ffi fs18 tac fw700 m-0 ${styles.dont}`} >Don't have a directory book? <br /> buy one  today for only</h4>
                <h3 className={`ffi tac fs28 ${styles.price}`}>$34.99</h3>
                <p className={`m-0 tac ffi fw500 fs16 ${styles.shipping}`}>Plus $7 Shipping fee </p>
                <a className={`ffi fw700 fs18 tac m-auto ${styles.btn}`} href='https://portal.genvite.com/auth/login' target='_self' >Sign In to order </a>
                <p className={`m-0 ffi fw400 fs14 tac cfff`} >When receiving your book, enter the promo code printed on the book and get refunded $42.00</p>
            </div>

        </div>
    </div>
  )
}

export default Directory
import styles from "./Roster.module.scss";

import printer from "../../../assets/imgs/printer-white.png";
import envelope from "../../../assets/imgs/envelope-white.png";
import selfServe from "../../../assets/imgs/self-print-white.png";
import phone from "../../../assets/imgs/phone-white.png";
import text from "../../../assets/imgs/text-white.png";
import email from "../../../assets/imgs/email-white.png";

const Roster = () => {
  return (
    <div className={styles.roster}>
      <div className={`df aic content-wrpr`}>
        <div className={styles.listWrpr}>
          <div className={styles.listItem}>
            <div className={styles.icon}>
              <img src={printer} alt="" />
            </div>
            <p className={`ffm fw700 fs15`}>Labels</p>
          </div>

          <div className={styles.listItem}>
            <div className={styles.icon}>
              <img src={envelope} alt="" />
            </div>
            <p className={`ffm fw700 fs15`}>Envlopes</p>
          </div>
          <div className={styles.listItem}>
            <div className={styles.icon}>
              <img src={selfServe} alt="" />
            </div>
            <p className={`ffm fw700 fs15`}>Self Service</p>
          </div>
          <div className={styles.listItem}>
            <div className={styles.icon}>
              <img src={phone} alt="" />
            </div>
            <p className={`ffm fw700 fs15`}>Phone Calls</p>
          </div>
          <div className={styles.listItem}>
            <div className={styles.icon}>
              <img src={text} alt="" />
            </div>
            <p className={`ffm fw700 fs15`}>Mass Text</p>
          </div>
          <div className={styles.listItem}>
            <div className={styles.icon}>
              <img src={email} alt="" />
            </div>
            <p className={`ffm fw700 fs15`}>
              Mass Emails<span>Coming soon</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roster;

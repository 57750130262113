import Landing from "../components/our-story/Landing";
import OnTheLabel from "../components/our-story/on-the-label/OnTheLabel";

const OurStory = () => {
  return (
    <>
      <Landing />
      <OnTheLabel />
    </>
  );
};

export default OurStory;

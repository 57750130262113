import styles from "./YourCall.module.scss";

const YourCall = () => {
  return (
    <div className={styles.yourCall}>
      <div className={`content-wrpr`}>
        <h5 className={`ffm fw800 fs32 tac`}>Your Simcha. Your Call.</h5>

        <p className={`ffm fw500 tac fs20`}>
          Every list is customizable to meet your needs. We offer an array of
          personalized features so that you can plan for a beautiful simcha with
          friends and family
        </p>

        <div className={`df aic`}>
          <a
            href="https://portal.genvite.com/auth/signup"
            rel="noopener noreferrer"
            className={`ffm fw600 fs18`}
          >
            Start Genvite Free
          </a>
        </div>
      </div>
    </div>
  );
};

export default YourCall;

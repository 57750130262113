import styles from "./Easy.module.scss";

import people from "../../assets/imgs/people.png";
import contacts from "../../assets/imgs/contacts.png";
import search from "../../assets/imgs/search.png";

const Easy = () => {
  return (
    <div className={styles.easy}>
      <div className={`content-wrpr`}>
        <div className={styles.easyCard}>
          <h3 className={`tac ffm fs28 fw700 ttuc`}>IT'S THIS SIMPLE</h3>
          <h5 className={`tac ffm fs22 fw700 ttuc`}>
            BUILD YOUR EVENT INVITATIONS LIST WITH JUST A FEW CLICKS
          </h5>

          <div className={`${styles.stepsWrpr}`}>
            <div className={`df ffc aic ${styles.stepWrpr}`}>
              <div className={`df acc ffm fs18 fw800 ${styles.icon}`}>
                <img src={people} alt="" />
              </div>
              <div className={`ffm fs17 tac fw500 ${styles.cntnt}`}>
                Our automated system suggests contacts in your network to select
                or reject - with automatic grouping by family, friends,
                neighbors, etc.
              </div>
            </div>
            <div className={`df ffc aic ${styles.stepWrpr}`}>
              <div className={`df acc ffm fs18 fw800 ${styles.icon}`}>
                <img src={contacts} alt="" />
              </div>
              <div className={`ffm fs17 tac fw500 ${styles.cntnt}`}>
                Our powerful search tools help you build your list, which
                already includes addresses, phone numbers and more.
                <span className={styles.sub}>
                  [automatically updated continuously]
                </span>
              </div>
            </div>
            <div className={`df ffc aic ${styles.stepWrpr}`}>
              <div className={`df  acc ffm fs18 fw800 ${styles.icon}`}>
                <img src={search} alt="" />
              </div>
              <div className={`ffm fs17 tac fw500 ${styles.cntnt}`}>
                Print labels or envelopes easily, or schedule phone calls and
                text messages conveniently for all your occasions and events.
              </div>
            </div>
          </div>
        </div>
        <p
          className={`ffm fw800 fs24 tac ${styles.firstP}`}
          // style={{ marginTop: "16px" }}
        >
          SO EASY, IT'S FREE!
        </p>

        <p
          className={`ffm fw600 fs22 tac ${styles.secondP}`}
          // style={{ marginTop: "16px" }}
        >
          YOUR CONTACT LIST REMAINS 100% FREE
        </p>
        <p className={`ffm fw600 fs22 tac ${styles.thirdP}`}>
          Select generously. You will have the opportunity to choose invitees
          from your list at checkout.
        </p>

        <div className={`df aic`}>
          <a
            href="https://portal.genvite.com/auth/signup"
            rel="noopener noreferrer"
            className={`tac ffm fs18 fw600 ${styles.startButton}`}
          >
            Start Genvite Free{" "}
          </a>
        </div>
      </div>
      <div className={`${styles.uneventful}`}>
        <div className={`content-wrpr df ffc aic`}>
          <h3 className={`ffm fs30 fw600`}>
            EVENT INVITATIONS MADE UNEVENTFUL
          </h3>
          <h5 className={`ffm fs20 fw600 tac`}>
            Developed by E-Z Labels, we offer an intuitive online experience
            skillfully designed to put together your simcha invitation or
            communication lists efficiently, quickly and accurately.
          </h5>
        </div>
      </div>
    </div>
  );
};

export default Easy;

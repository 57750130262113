import { useState } from "react";
import styles from "./Accordion.module.scss";

import arrow from "../../../assets/imgs/accordion-arrow.png";

const Accordion = ({ data, title }) => {
  const [open, setOpen] = useState(undefined);
  return (
    <div className={styles.accordion}>
      <p className={`ffm fs30 fw700 tac c084571`}>{title}</p>
      {data.map((line, i) => (
        <div className={`c084571 ${styles.line}`}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              open === i ? setOpen(undefined) : setOpen(i);
            }}
            className={`df aic ffm fs20 fw600 ${styles.q}`}
          >
            {line.q}{" "}
            <div className={`mla ${styles.openerWrpr}`}>
              <img
                className={`${open === i ? styles.rotate : ""}`}
                src={arrow}
                alt=""
              />
            </div>
          </div>
          {open === i ? (
            <div className={`df aic ffm fs18 fw450 ${styles.a}`}>{line.a}</div>
          ) : undefined}
        </div>
      ))}
    </div>
  );
};

export default Accordion;

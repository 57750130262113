import React from 'react'
import styles from "./discover.module.scss"
import genTree from "../../assets/imgs/gentree.svg"
import genTreeSystem from "../../assets/imgs/gentree-system.svg"
import lineDot from "../../assets/imgs/linedot.svg"

const Discover = () => {
  return (
    <div className={`content-wrpr my-5 `}>
        <div className={`${styles.banner} row py-3`}>
            <div className={`col-md-3 d-flex px-5 py-3 flex-column align-items-center justify-content-around ${styles.leftSide}`}>
                <div className='d-flex flex-column align-items-center'>
                    <p className={`ffi fw800 fs14 ${styles.new}`} >New</p>
                    <p className={`ffi fw700 fs20 ${styles.feature}`} >Try our all new feature</p>
                </div>
                <div className='d-flex flex-column align-items-center'>
                    <img src={genTree} className='object-fit-contain w-100' alt="gentree logo" />
                    <p className={`ffi fw600  ${styles.connecting}`} >Connecting Generations</p>
                </div>
            </div>
            <div className='col-md-9 py-2'>
                <div className='row ps-4'>
                    <div className='col-md-8'>
                        <h5 className={`ffi fw800 fs20 ${styles.discover}`} >Discover Family Trees of Family and Friends.</h5>
                        <p className={`ffi fw500 fs16 ${styles.text}`} >Uncover connections between family members. Our software displays detailed relationship paths, allowing you to see how individuals are interconnected within the family tree. Discover ancestors, descendants, and extended family members with just a few clicks.</p>
                        <div className='mt-5'>
                            <a className={`ffi fw600 fs16 ${styles.upgradeBtn}`} href='https://portal.genvite.com/auth/login' target='_self' >Upgrade to premium </a>
                            <a className={`ffi fw600 fs16 ${styles.freeBtn}`} href='https://portal.genvite.com/auth/login' target='_self' >Try free</a>
                        </div>
                    </div>
                    <div className='col-md-4 d-flex flex-column justify-content-between gap-4'>
                        <img src={lineDot} alt="linedot" className={styles.lineTop} />
                        <img src={genTreeSystem} className='object-fit-contain w-100' alt="gentree-system" />
                        <img src={lineDot} alt="linedot" className={styles.line} />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Discover
import Landing from "../components/services/Landing";
import Points from "../components/services/points/Points";
import Roster from "../components/services/roster/Roster";
import WeOffer from "../components/services/we-offer/WeOffer";
const Services = () => {
  return (
    <>
      <Landing />
      <Roster />
      <WeOffer />
      <Points />
    </>
  );
};

export default Services;

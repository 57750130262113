import { useState } from "react";
import FAQs from "../components/how-it-works/faq/FAQ";
import Genvite from "../components/how-it-works/genvite/Genvite";
import HowTos from "../components/how-it-works/how-tos/HowTos";

import styles from "./HowItWorks.module.scss";
import Discover from "../components/discover/Discover";

const HowItWorks = () => {
  const [tab, setTab] = useState("genvite");

  return (
    <div className={styles.how}>
      <div className={`df acc ffc bc084571 ${styles.hdr}`}>
        <div className={`df acc ffc content-wrpr`}>
          <h2 className={`ffm fw800 fs32 cfff`}>How Genvite works</h2>
        </div>
        <div className={`content-wrpr ${styles.sectionsWrpr}`}>
          <div className={`${styles.sectionsWrpr}`}>
            <div
              onClick={() => setTab("genvite")}
              className={`df acc ffm fw800 fs24 cfff bc145F95 ${
                tab === "genvite" ? "bc548ED1" : "bc145F95"
              }  ${styles.sec}`}
            >
              Genvite
            </div>
            <div
              onClick={() => setTab("how")}
              className={`df acc ffm fw800 fs24 cfff bc145F95 ${
                tab === "how" ? "bc548ED1" : "bc145F95"
              }  ${styles.sec}`}
            >
              How to's
            </div>
            <div
              onClick={() => setTab("faq")}
              className={`df acc ffm fw800 fs24 cfff ${
                tab === "faq" ? "bc548ED1" : "bc145F95"
              } ${styles.sec}`}
            >
              FAQ's
            </div>
          </div>
        </div>
      </div>

      <div className={`content-wrpr ${styles.content}`}>
        {tab === "genvite" ? (
          <Genvite />
        ) : tab === "how" ? (
          <HowTos />
        ) : (
          <FAQs />
        )}
      </div>

      <Discover />

      <div className={`bc145F95 tac ffm cfff fs22 fw500 ${styles.ftr}`}>
        LET US HELP GET THE WORD OUT
      </div>
    </div>
  );
};

export default HowItWorks;

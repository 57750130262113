import { Link } from "react-router-dom";

import styles from "./Footer.module.scss";
// import pci from "../../assets/imgs/pci.png";
import logo from "../../assets/imgs/footer-logo.png";

const Footer = () => {
  return (
    <>
      <div className={styles.footer}>
        <div className={`content-wrpr ${styles.contentWrpr}`}>
          <div className={`df ${styles.innerContentWrpr}`}>
            <div className={styles.footerSec}>
              <h5 className={`ffm fw900 fs14`}>Site Map</h5>
              <Link className={`ffm fw400 fs14`} to="/">
                Home
              </Link>
              <Link className={`ffm fw400 fs14`} to="/platform">
                Our Platform
              </Link>
              <Link className={`ffm fw400 fs14`} to="/pricing">
                Pricing
              </Link>
              <Link className={`ffm fw400 fs14`} to="/services">
                Services
              </Link>
              <Link className={`ffm fw400 fs14`} to="/our-story">
                Our Story
              </Link>
              <Link className={`ffm fw400 fs14`} to="/how-it-works">
                How It Works
              </Link>
              <Link className={`ffm fw400 fs14`} to="/contact">
                Contact Us
              </Link>
            </div>
            {/* <div className={styles.footerSec}>
              <h5 className={`ffm fw900 fs14`}>Features</h5>
              <Link className={`ffm fw400 fs14`} to="/">
                Automatic Suggestions
              </Link>
              <Link className={`ffm fw400 fs14`} to="/">
                Customization
              </Link>
              <Link className={`ffm fw400 fs14`} to="/">
                Self-mail or Full Service
              </Link>
              <Link className={`ffm fw400 fs14`} to="/">
                Up-to-date Information
              </Link>
            </div> */}
            <div className={styles.footerSec}>
              <h5 className={`ffm fw900 fs14`}>Genvite</h5>
              <Link to="/terms" className={`ffm fw400 fs14`}>
                Terms of Services
              </Link>
              <Link to="/privacy" className={`ffm fw400 fs14`}>
                Privacy Policy
              </Link>

              {/* <img src={pci} alt="" /> */}
            </div>

            <div className={`mla ${styles.logoSec}`}>
              <img src={logo} alt="" />
              <div className={styles.divider}></div>
              <div className={styles.contactPSec}>
                <p className={`ffm fw400 fs16`}>CALL US</p>
                <p className={`ffm fw500 fs16`}>1.646.927.1198</p>
              </div>
              <div className={styles.contactPSec}>
                <p className={`ffm fw400 fs16`}>EMAIL US</p>
                <p className={`ffm fw500 fs16`}>info@genvite.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.copy}>
        <div className={`ffm fw400 fs14 content-wrpr ${styles.contentWrpr}`}>
          Copyright 2023 - Genvite Inc. - <span>All rights reserved</span>
        </div>
      </div>
    </>
  );
};

export default Footer;

import { NavLink } from "react-router-dom";

import styles from "./Header.module.scss";

import logo from "../../assets/imgs/logo.png";
import { useState } from "react";

const Header = () => {
  const [openMobile, setOpenMobile] = useState(false);
  return (
    <div className={`df aic ${styles.header}`}>
      <div className={`df aic content-wrpr ${styles.contentWrpr}`}>
        <NavLink to="/" onClick={() => setOpenMobile(false)}>
          <img src={logo} alt="" />
        </NavLink>

        <div
          onClick={() => setOpenMobile(!openMobile)}
          className={`mla df acc ${styles.mobileOpener}`}
        >
          <div
            className={`${styles.opener} ${openMobile ? styles.open : ""}`}
          ></div>
        </div>

        <div
          className={`df aic mla ffm fw600 ${styles.navs} ${
            openMobile ? styles.showMobile : ""
          }`}
        >
          <NavLink
            onClick={() => setOpenMobile(false)}
            to="/"
            className={({ isActive }) =>
              isActive ? `${styles.active} ${styles.nav}` : `${styles.nav}`
            }
          >
            Home
          </NavLink>
          <NavLink
            onClick={() => setOpenMobile(false)}
            to="/platform"
            className={({ isActive }) =>
              isActive ? `${styles.active} ${styles.nav}` : `${styles.nav}`
            }
          >
            Our Platform
          </NavLink>
          <NavLink
            onClick={() => setOpenMobile(false)}
            to="/pricing"
            className={({ isActive }) =>
              isActive ? `${styles.active} ${styles.nav}` : `${styles.nav}`
            }
          >
            Pricing
          </NavLink>
          <NavLink
            onClick={() => setOpenMobile(false)}
            to="/services"
            className={({ isActive }) =>
              isActive ? `${styles.active} ${styles.nav}` : `${styles.nav}`
            }
          >
            Services
          </NavLink>
          <NavLink
            onClick={() => setOpenMobile(false)}
            to="/our-story"
            className={({ isActive }) =>
              isActive ? `${styles.active} ${styles.nav}` : `${styles.nav}`
            }
          >
            Our Story
          </NavLink>
          <NavLink
            onClick={() => setOpenMobile(false)}
            to="/how-it-works"
            className={({ isActive }) =>
              isActive ? `${styles.active} ${styles.nav}` : `${styles.nav}`
            }
          >
            How It works
          </NavLink>
          <NavLink
            onClick={() => setOpenMobile(false)}
            to="/contact"
            className={({ isActive }) =>
              isActive ? `${styles.active} ${styles.nav}` : `${styles.nav}`
            }
          >
            Contact Us
          </NavLink>
          <div className={`df aic ${styles.links}`}>
            <a
              onClick={() => setOpenMobile(false)}
              rel="noopener noreferrer"
              href="https://portal.genvite.com/auth/login"
              className={`df acc ffm fw600 ${styles.nav} ${styles.clearButton}`}
            >
              Log In
            </a>

            <a
              onClick={() => setOpenMobile(false)}
              rel="noopener noreferrer"
              href="https://portal.genvite.com/auth/signup"
              className={`df acc ffm fw600 ${styles.nav} ${styles.fullButton}`}
            >
              Get Started
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

import AutoSuggestions from "../components/platform/auto-suggestions/AutoSuggestions";
import Easy from "../components/platform/easy/Easy";
import PlatformLanding from "../components/platform/landing/Landing";
import Simple from "../components/platform/simple/Simple";
import YourCall from "../components/platform/YourCall";

const Platform = () => {
  return (
    <>
      <PlatformLanding />
      <AutoSuggestions />
      <Simple />
      <YourCall />
      <Easy />
    </>
  );
};

export default Platform;

import React from 'react'
import GentreeLogo from "../../../assets/imgs/gentree-modal.svg"
import GentreeSys from "../../../assets/imgs/gentree-sys.svg"
import styles from "./gentreeModal.module.scss"
import cross from "../../../assets/imgs/cross.svg"

const GentreeModal = ({setShow}) => {
  return (
    <>
    <div id="overlay" class={styles.overlay}></div>

    <div className={`row ${styles.modal}`}>
        <div className={`col-md-5 ${styles.leftSide} px-5 pt-3 pb-0 d-flex flex-column align-items-center`}>
            <h4 className={`ffi fw800 fs20 ${styles.intro}`}>Introducing</h4>
            <img src={GentreeLogo} alt="gentree logo" className='w-100 mb-2' />
            <p className={`ffi fw400 fs14 ${styles.connect}`} >Connecting Generations</p>
            <img src={GentreeSys} alt="gentree system" className='w-100 mb-0' />
        </div>
        <div className={`col-md-7 ${styles.rightSide}`}>
            <h4 className={`ffi fw800 fs20 cfff ${styles.text}`}>Discover Family Trees of Family and Friends.</h4>
            <p className={`ffi fw500 fs16 cfff ${styles.text}`} >Uncover connections between family members. Our software displays detailed relationship paths, allowing you to see how individuals are interconnected within the family tree. Discover ancestors, descendants, and extended family members with just a few clicks.</p>
            <div className='mt-5'>
                <a className={`ffi fw600 fs16 ${styles.upgrade}`} href='https://portal.genvite.com/auth/login' target='_self' >Upgrade to premium </a>
                <a className={`ffi fw600 fs16 ${styles.free}`} href='https://portal.genvite.com/auth/login' target='_self' >Try Free</a>
            </div>
            <button className={styles.close} onClick={()=>setShow(false)} >
                <img src={cross} alt="cross" />
            </button>
        </div>
    </div>
    </>
    
  )
}

export default GentreeModal
import Accordion from "../../ui/accordion/Accordion";

import styles from "./HowTos.module.scss";

const HowTos = () => {
  return (
    <div className={styles.howTos}>
      <Accordion
        title="HOW-TO'S"
        data={[
          {
            q: "How to build My Contact list",
            a: (
              <div className={`fs18 ffm fw500 ${styles.accordionA}`}>
                <ul className={styles.primary}>
                  <li className={styles.primary}>
                    <span className={`ffm fw700`}>1. Suggestions </span> - Go
                    through suggestions - use the settings tab to configure the
                    extent of contacts suggested to you and use the filtering
                    tools within this section to ease the process. (When using
                    suggestions, you are benefiting from our services like auto
                    grouping, relation labels, etc.)
                  </li>
                  <li className={styles.primary}>
                    <span className={`ffm fw700`}>2. Master </span> - Select
                    from master, use our powerful filtering and map selection
                    tools to gather additional contacts that were not included
                    in the suggestions.
                  </li>
                  <li className={styles.primary}>
                    <span className={`ffm fw700`}>3. Manual Entries </span> -
                    For your additional contacts that are not listed in our
                    master list, you can manually enter them through our
                    personal contacts entry form
                  </li>
                  <li className={styles.primary}>
                    If you already have an excel spreadsheet with your contacts,
                    you can use our upload tool.
                  </li>
                </ul>
              </div>

              // <div className={`fs18 ffm fw500 ${styles.accordionA}`}>
              //   <ul className={styles.primary}>
              //     <li className={styles.primary}>
              //       <span className={`ffm fw700`}>1. Suggestions </span> - Go
              //       through suggestions - use the settings tab to configure the
              //       extent of contacts suggested to you and use the filtering
              //       tools within this section to ease the process. (When using
              //       suggestions, you are benefiting from our services like auto
              //       grouping, relation labels, etc.)
              //       <ul className={styles.secondary}>
              //         <li className={styles.secondary}>
              //           Li 2
              //           <ul className={styles.third}>
              //             <li className={styles.third}>Li 2</li>
              //           </ul>
              //         </li>
              //       </ul>
              //     </li>
              //   </ul>
              // </div>
            ),
          },
          {
            q: "Use Contacts from My Suggestions list",
            a: (
              <div className={`fs18 ffm fw500 ${styles.accordionA}`}>
                <ul className={styles.primary}>
                  <li className={styles.primary}>
                    Click "Add to list" near the contacts you want to add or click
                    "All" then click on "Add Selected" [You can also select a filter and select "All"
                    based on the filtered results, then click on "Add Selected"]
                  </li>
                  <li className={styles.primary}>
                    Click on Settings Tab to personalize suggested contacts
                    <span className={styles.blockLine}>Choose from among:</span>
                    <span className={styles.blockLine}>- Family</span>
                    <span className={styles.blockLine}>- Mechitunim</span>
                    <span className={styles.blockLine}>- Yeshivah</span>
                    <span className={styles.blockLine}>- Friends</span>
                  </li>
                  <li className={styles.primary}>
                    Select which circle of relatives/acquaintances to include,
                    in order to modify the amount and type of contacts suggested
                    to you
                  </li>
                </ul>
              </div>
            ),
          },
          {
            q: "Select from the Master List of over 38,000 contacts",
            a: (
              <div className={`fs18 ffm fw500 ${styles.accordionA}`}>
                <ul className={styles.primary}>
                  <li className={styles.primary}>Click on Master List Tab</li>

                  <li className={styles.primary}>
                    Use the Filter Button on the right hand side
                    <ul className={styles.secondary}>
                      <li className={styles.secondary}>
                        Filter by location
                        <ul className={styles.third}>
                          <li className={styles.third}>
                            By City, Zip, or Region
                          </li>
                          <li className={styles.third}>
                            By Address range (i.e. 1–120 Main Street)
                          </li>
                        </ul>
                      </li>
                      {/* ********** */}
                      <li className={styles.secondary}>
                        Filter by neighborhood using our unique Map feature
                        <ul className={styles.third}>
                          <li className={styles.third}>Click on Map Image</li>
                          <li className={styles.third}>
                            Enter address to find specific location
                          </li>
                          <li className={styles.third}>
                            Zoom the map as needed
                          </li>
                          <li className={styles.third}>Press Draw Polygon</li>
                          <li className={styles.third}>
                            Use the mouse to draw a specific area in the map.
                            Include streets & houses that have your close
                            neighbors.
                          </li>
                          <li className={styles.third}>Click Apply Polygon</li>
                          <li className={styles.third}>
                            Click Apply again on the filters page
                          </li>
                          <li className={styles.third}>
                            From the returned results, select your desired
                            contacts and add them to your list or a specific
                            group.
                          </li>
                        </ul>
                      </li>
                      {/* ********** */}
                      <li className={styles.secondary}>
                        Group by last name for easy viewing
                        <ul className={styles.third}>
                          <li className={styles.third}>
                            Click on Master List Tab
                          </li>
                          <li className={styles.third}>
                            Move the Group By Last Name toggle found on the top
                            right-hand side from OFF to ON
                          </li>
                        </ul>
                      </li>
                      {/* *********** */}
                      <li className={styles.secondary}>
                        Search by word
                        <ul className={styles.third}>
                          <li className={styles.third}>
                            Click on Master List Tab
                          </li>
                          <li className={styles.third}>
                            Click in the search bar
                          </li>
                          <li className={styles.third}>
                            Type your specific text. You can type just the first
                            few letters or numbers of a name, address, or phone
                            number.
                          </li>
                          <li className={styles.third}>
                            Click on the little arrow on the left side of the
                            search bar to limit the search to 1 specific field
                            i.e. First Name, Last Name, Address, Phone Number
                          </li>
                        </ul>
                      </li>
                      {/* *********** */}
                    </ul>
                  </li>
                </ul>
              </div>
            ),
          },
          {
            q: "Manually add a contact",
            a: (
              <div className={`fs18 ffm fw500 ${styles.accordionA}`}>
                <ul className={styles.primary}>
                  <li className={styles.primary}>Click on My Contacts Tab</li>
                  <li className={styles.primary}>Click on My Contact List</li>
                  <li className={styles.primary}>
                    On the top right-hand side, click Add Contacts
                  </li>
                  <li className={styles.primary}>
                    Click Add Contacts Manually
                  </li>
                  <li className={styles.primary}>Enter the contact’s info</li>
                  <li className={styles.primary}>Press Add Contact.</li>
                  <li className={styles.primary}>
                    Press “Save to my Contact” to add them into your My Contacts
                    list
                  </li>
                </ul>
              </div>
            ),
          },
          {
            q: "Import your own Excel sheet of contacts",
            a: (
              <div className={`fs18 ffm fw500 ${styles.accordionA}`}>
                <ul className={styles.primary}>
                  <li className={styles.primary}>Click on My Contacts Tab</li>
                  <li className={styles.primary}>Click on My Contact List</li>
                  <li className={styles.primary}>Click Add Contacts</li>
                  <li className={styles.primary}>Select Upload Spreadsheet</li>
                  <li className={styles.primary}>Click Download sample Excel file</li>
                  <li className={styles.primary}>Open the downloaded excel file on your computer, for best results, copy all your contacts into this file. (DO NOT add, remove or rename columns. You don’t need to populate all columns)</li>
                  <li className={styles.primary}>In order to utilize our most advanced features [e.g. automatically connecting the contacts to its respective contact in the master list, detect address updates, auto correct address spelling, USPS address validation, etc.] enter the contacts addresses in the following format “123 Main St. #1-A” (where there is an Apt. it should be entered with a “#” and if the apt. has a number and a letter it should have a “-“).  If you are entering phone numbers, you can enter them in either format</li>
                  <li className={styles.primary}>Save the file on your computer</li>
                  <li className={styles.primary}>Click on Browse Files, to locate the file on your computer</li>
                  <li className={styles.primary}>Make sure you see your correct file selected</li>
              <li className={styles.primary}>
                    Click Submit
                  </li>
                </ul>
              </div>
            ),
          },
          {
            q: "Choose from a phonebook and filter by ID number",
            a: (
              <div className={`fs18 ffm fw500 ${styles.accordionA}`}>
                <ul className={styles.primary}>
                  <li className={styles.primary}>Click on My Contacts Tab</li>
                  <li className={styles.primary}>Click on My Contact List</li>
                  <li className={styles.primary}>Click Add Contacts</li>
                  <li className={styles.primary}>
                    Select Enter ID’s From Phone Book
                  </li>
                  <li className={styles.primary}>
                    Select the Phonebook source and year
                  </li>
                  <li className={styles.primary}>
                    Enter ID numbers from phonebook
                  </li>
                  <li className={styles.primary}>
                    Populate additional If you want to change the title
                    different from default, add a suffix (i.e. & Family) or add
                    to a specific group
                  </li>
                  <li className={styles.primary}>Click Save to my Contacts</li>
                </ul>
              </div>
            ),
          },
          {
            q: "What can I use my contacts for?",
            a: (
              <div className={`fs18 ffm fw500 ${styles.accordionA}`}>
                <ul className={styles.primary}>
                  <li className={styles.primary}>
                    To order labels or print my own
                  </li>
                  <li className={styles.primary}>
                    To order envelopes printed with contact info
                  </li>
                  <li className={styles.primary}>
                    To send out mass phone calls
                  </li>
                  <li className={styles.primary}>
                    To send out mass email or text
                  </li>
                  <li className={styles.primary}>
                    To print out my contacts’ info, so I have them handy
                  </li>
                </ul>
              </div>
            ),
          },
          {
            q: "How do I make an order?",
            a: (
              <div className={`fs18 ffm fw500 ${styles.accordionA}`}>
                <ul className={styles.primary}>
                  <li className={styles.primary}>Click on My Contacts Tab</li>
                  <li className={styles.primary}>
                    Click on My Contact List or on My Groups
                  </li>
                  <li className={styles.primary}>
                    Select all, your desired contacts, or group(s) of contacts
                  </li>
                  <li className={styles.primary}>
                    Click on Add to Cart on top right of screen
                  </li>
                  <li className={styles.primary}>
                    Click on the blue shopping cart symbol on the very top right
                    of screen (the cart button will show the number of contacts
                    currently in cart)
                  </li>
                  <li className={styles.primary}>
                    Select your order type, and follow the prompts to complete
                    checkout
                  </li>
                </ul>
              </div>
            ),
          },
          {
            q: "How to edit which contacts are suggested to me",
            a: (
              <div className={`fs18 ffm fw500 ${styles.accordionA}`}>
                <ul className={styles.primary}>
                  <li className={styles.primary}>Click on Settings Tab</li>
                  <li className={styles.primary}>
                    Choose which tab to edit: Family, Mechitunim, Yeshivah,
                    Friends
                  </li>
                  <li className={styles.primary}>
                    Move the toggle right or left to turn on or off a setting. A
                    blue toggle indicates a setting feature that is on. A gray
                    toggle indicates feature setting is off.
                  </li>
                  <li className={styles.primary}>
                    Select which circle of relatives or acquaintances to include
                  </li>
                  <li className={styles.primary}>
                    Note:
                    <ul className={styles.secondary}>
                      <li className={styles.secondary}>
                        You can remove suggested contacts from your list by
                        clicking Remove near the contact or by clicking Remove
                        All to empty your suggestion list.
                      </li>
                      <li className={styles.secondary}>
                        To reset and bring back removed suggestions, click Reset
                        button found on top-right hand corner.
                      </li>
                      <li className={styles.secondary}>
                        Any changes you are making in the settings screen will
                        NOT affect any contacts you already have in your "My
                        Contact List” this is only controlling which contacts to
                        show up in “My Suggestions"
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            ),
          },
          {
            q: "How to edit my Contact List",
            a: (
              <div className={`fs18 ffm fw500 ${styles.accordionA}`}>
                <ul className={styles.primary}>
                  <li className={styles.primary}>
                    To delete
                    <ul className={styles.secondary}>
                      <li className={styles.secondary}>Select contact(s)</li>
                      <li className={styles.secondary}>
                        Press the delete symbol on top of screen
                      </li>
                      <li className={styles.secondary}>Confirm Delete</li>
                      <li className={styles.secondary}>
                        You can also press on the 3 dotes near the name to
                        delete
                      </li>
                    </ul>
                  </li>

                  <li className={styles.primary}>
                    To add
                    <ul className={styles.secondary}>
                      <li className={styles.secondary}>
                        (See How to Build my Contact List)
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            ),
          },
          {
            q: "How to request an edit to a contact that I cannot edit on my own",
            a: (
              <div className={`fs18 ffm fw500 ${styles.accordionA}`}>
                <ul className={styles.primary}>
                  <li className={styles.primary}>
                    Hit the three dots near the desired contact
                  </li>
                  <li className={styles.primary}>Press Edit</li>
                  <li className={styles.primary}>
                    In the Other Changes field, type the edit request you want
                  </li>
                  <li className={styles.primary}>Click Save</li>
                </ul>
              </div>
            ),
          },
          {
            q: "How to use Groups feature to categorize contacts under specific labels",
            a: (
              <div className={`fs18 ffm fw500 ${styles.accordionA}`}>
                <ul className={styles.primary}>
                  <li className={styles.primary}>
                    To add a new group
                    <ul className={styles.secondary}>
                      <li className={styles.secondary}>
                        Click on My Contacts tab
                      </li>
                      <li className={styles.secondary}>My Groups</li>
                      <li className={styles.secondary}>Click Add Group</li>
                      <li className={styles.secondary}>
                        Type your new Group name
                      </li>
                      <li className={styles.secondary}>Click Create Group</li>
                    </ul>
                  </li>

                  <li className={styles.primary}>
                    To delete a group
                    <ul className={styles.secondary}>
                      <li className={styles.secondary}>
                        Click on My Contacts tab
                      </li>
                      <li className={styles.secondary}>My Groups</li>
                      <li className={styles.secondary}>
                        Hit the three dots near the desired group
                      </li>
                      <li className={styles.secondary}>
                        You’ll be prompted with options “Remove Group” or
                        “Remove Completely”
                      </li>
                      <ul className={styles.third}>
                        <li className={styles.third}>
                          Remove Group – will just remove the group (and sub-
                          groups) but all contacts within the group will remain
                          in “My contacts”, also if some of the contacts are
                          associated with another group they will not be
                          changed.
                        </li>
                        <li className={styles.third}>
                          Remove completely – will remove the group (and sub-
                          groups) and it will also completely remove all
                          contacts from “My contacts” as well as any other
                          groups those contacts are associated with.
                        </li>
                      </ul>
                      <li className={styles.secondary}>Confirm Remove</li>
                      <li className={styles.secondary}>
                        To delete multiple groups at once, you can use the
                        garbage can icon on top left of the screen
                      </li>
                    </ul>
                  </li>

                  <li className={styles.primary}>
                    To add a sub-group
                    <ul className={styles.secondary}>
                      <li className={styles.secondary}>
                        Click Add Sub near the desired group
                      </li>
                      <li className={styles.secondary}>
                        Type your new Sub-Group name
                      </li>
                      <li className={styles.secondary}>Click Create Group</li>
                    </ul>
                  </li>

                  <li className={styles.primary}>
                    To add a contact to a group or sub-group
                    <ul className={styles.secondary}>
                      <li className={styles.secondary}>
                        Click on My Contacts tab
                      </li>
                      <li className={styles.secondary}>
                        Select which contact(s) you want to add to a group
                      </li>
                      <li className={styles.secondary}>
                        Click on the blue Add To Group button
                      </li>
                      <li className={styles.secondary}>
                        Choose which group you want to add contact to
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            ),
          },
          {
            q: "How to edit a contact’s information",
            a: (
              <div className={`fs18 ffm fw500 ${styles.accordionA}`}>
                <ul className={styles.primary}>
                  <li className={styles.primary}>
                    Click on the three dots to the side of that contacts’ name
                  </li>
                  <li className={styles.primary}>Hit Edit</li>
                  <li className={styles.primary}>
                    Enter new name, address etc.
                  </li>
                  <li className={styles.primary}>
                    Click Save to save the updated contact information
                  </li>
                  <li className={styles.primary}>
                    (Note: Editing contact’s basic info generates a notification
                    to Genvite. If info is approved by our team as being
                    accurate, the updated info will be made available to all
                    users.)
                  </li>
                </ul>
              </div>
            ),
          },
          {
            q: "How can I know how a contact is related or acquainted with me?",
            a: (
              <div className={`fs18 ffm fw500 ${styles.accordionA}`}>
                <ul className={styles.primary}>
                  <li className={styles.primary}>
                    Hover over the label on left hand side of the contact’s
                    name, and the information will pop up. For example:
                    Great-nephew, Mechutanim etc. (This feature is available
                    only for contacts that entered the list trough suggestions)
                  </li>
                </ul>
              </div>
            ),
          },
          {
            q: "Where do I view past orders or draft orders?",
            a: (
              <div className={`fs18 ffm fw500 ${styles.accordionA}`}>
                <ul className={styles.primary}>
                  <li className={styles.primary}>Click on Orders</li>
                  <li className={styles.primary}>
                    Click View More to see order details
                  </li>
                </ul>
              </div>
            ),
          },
          {
            q: "How to change my personal account & payment information?",
            a: (
              <div className={`fs18 ffm fw500 ${styles.accordionA}`}>
                <ul className={styles.primary}>
                  <li className={styles.primary}>
                    On the right hand-corner, click on the arrow near your name
                  </li>
                  <li className={styles.primary}>Select Account</li>
                  <li className={styles.primary}>Edit your information</li>
                  <li className={styles.primary}>Hit Save</li>
                </ul>
              </div>
            ),
          },
          {
            q: "How to change my personal information on the Master List?",
            a: (
              <div className={`fs18 ffm fw500 ${styles.accordionA}`}>
                <ul className={styles.primary}>
                  <li className={styles.primary}>Go to Setting tab</li>
                  <li className={styles.primary}>
                    On the upper part of the screen “Master List Profile” click
                    on the blue down arrow
                  </li>
                  <li className={styles.primary}>Click on Edit profile</li>
                  <li className={styles.primary}>Enter your desired changes</li>
                  <li className={styles.primary}>Click Save</li>
                </ul>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default HowTos;

import styles from "./OnTheLabel.module.scss";

import listIcon from "../../../assets/imgs/list-icon.png";
import { Link } from "react-router-dom";
import Discover from "../../discover/Discover";

const OnTheLabel = () => {
  return (
    <>
      <div className={styles.onTheLabel}>
        <h4 className={`ffm fw700 fs34 tac c084571`}>
          MORE THAN WHAT'S ON THE LABEL
        </h4>
        <p className={`ffm fs24 fw600 tac c04395F ${styles.header}`}>
          The Genvite platform is far more than an easy-to-use online directory.
          A staggering number of creative and customizable features make this
          service unique to each user and whatever their invitation needs are:
        </p>

        <div className={styles.list}>
          {[
            "Looking to invite a new Mechitin's family?",
            "Need addresses for your Chosson's grade mates?",
            "Need updated addresses for those who moved?",
            "Want to upload your own Excel sheet list?",
          ].map((line) => (
            <div className={`df aic ${styles.listItem}`}>
              <div className={styles.icon}>
                <img src={listIcon} alt="" />
              </div>
              <p className={`ffm fs20 fw600 c04395F`}>{line}</p>
            </div>
          ))}
        </div>
        <p className={`ffm fs34 fw700 tac c084571 ${styles.lastText}`}>
          OUR MISSION IS YOUR PEACE OF MIND
        </p>

        <div className={`df aic`}>
          <Link to="/contact" className={`ffm fw700 fs18 bc548ED1 cfff`}>
            Contact us
          </Link>
        </div>
      </div>

      <Discover />

      <div className={`bc145F95 tac ffm cfff fs22 fw500 ${styles.ftr}`}>
        LET US HELP GET THE WORD OUT
      </div>
    </>
  );
};

export default OnTheLabel;

import React from 'react'
import pricingLandingImg from "../../../../assets/imgs/pricing-landing.png";
import styles from "./banner.module.scss"

const PricingBanner = () => {
  return (
    <div className={`df aic content-wrpr ${styles.banner}`}>
        <div className={`${styles.imgWrpr}`} >
          <img src={pricingLandingImg} alt="price-landing" />
        </div>
        <div className={`df ffc ${styles.right}`} >
          <h2 className={`fs22 fw700 ffm ${styles.heading}`}>Build Your Contact List 100% Free!</h2>
          <p className={`fs15 fw500 ffm ${styles.para}`}>Create an account to access powerful tools like contact suggestions, filters, search, maps, and more — all at no cost. You can select and invite contacts from your list during checkout.</p>
        <div>
          <a className={`fw600 fs16 tac ffm ${styles.btn}`} href='https://portal.genvite.com/auth/signup' target='_self' >Get Started</a>
        </div>
        </div>
    </div>
  )
}

export default PricingBanner
import Accordion from "../../ui/accordion/Accordion";

import styles from "./FAQ.module.scss";

const FAQs = () => {
  return (
    <div className={styles.faqs}>
      <Accordion
        title="Frequently Asked Questions"
        data={[
          {
            q: "I selected 3rd Circle suggestions and I'm not getting any of my Second Cousins",
            a: "Family relationships are based on collected data and may be incomplete at distant levels. You can reach out to us, we will work with you to further connect your family.",
          },
          {
            q: "Some of my family members are missing from My Suggestions",
            a: (
              <div className={`fs18 ffm fw500 ${styles.accordionA}`}>
                <ul className={styles.primary}>
                  <li className={styles.primary}>
                    Check if those missing contact(s) are already in your "My
                    Contact List"
                  </li>
                  <li className={styles.primary}>
                    Look up in "Master List" if those contact(s) exist and if
                    the parent information is correct, [you may also want to
                    check if the parents of those contact(s) have their parents
                    listed correctly] if it's wrong, please submit a change
                    request and we will update them.
                  </li>
                </ul>
              </div>
            ),
          },
          {
            q: "I uploaded an Excel sheet with my contacts, but many contacts did not associate with their respective contacts in the Master List",
            a: `For best practice, please refer to the instructions "Import your own Excel sheet of contacts" in the How-to's section, and make sure to follow the instructions exactly`,
          },
          {
            q: "Are all Genvite services available for users without internet access?",
            a: `Sure! Please contact our office, we will work with you to get the best Genvite experience even if you are offline.`,
          },
          {
            q: "Will I be getting unsolicited calls or messages by providing you with my phone numbers?",
            a: `We take user privacy very seriously. Your phone numbers are secure within our platform. We will never send unsolicited calls. This platform may be used by customers to send pre-recorded non-commercial announcements or invitations to selected contacts in their circle (e.g. friends & family) for their upcoming social events.`,
          },
               {
            q: "Will this platform work on my mobile device?",
            a: `The platform is not currently optimized for mobile devices, for the best Genvite experience use a PC or laptop.`,
          },
           {
            q: "Why am I not seeing full phone numbers and addresses?",
            a: `We are masking full phone numbers and apartment numbers from public view, they will appear properly once you create an order.`,
          },
           {
            q: "If a contact is listed in multiple groups, will they appear more than once when I create a mailing or sending phone calls?",
            a: `Contacts can be associated with multiple groups, but when you create an order, you will see them in cart listed only once, even if you added the same contact from multiple groups.`,
          },
        ]}
      />
    </div>
  );
};

export default FAQs;
